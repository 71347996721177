<template>
	<div id="header" class="header__wrapper header__sticky">
		<div class="header__vw header__ktm">
			<div class="header__grid flex justify-between">
				<div class="flex flex-col md:flex-row py-4 justify-between w-full">
					<div class="wrapper-submenu flex md:hidden" style="justify-content: flex-start; align-items: center">
						<div class="container-submenu">
							<client-only>
								<Submenu :config="{ submenuConfig }" />
							</client-only>
						</div>
					</div>
					<div class="flex items-center w-full justify-between">
						<!-- Submenu -->
						<div class="wrapper-submenu hidden md:flex mr-8 items-center justify-start">
							<div class="container-submenu">
								<client-only>
									<Submenu :config="{ submenuConfig }" />
								</client-only>
							</div>
						</div>

						<div class="flex justify-center items-center">
							<Localizacao class="ktm" @botaoMinhaLocalizacaoClick="botaoMinhaLocalizacaoClick" :selecionouCidade="selecionouCidade" :cidadeSelecionada="cidadeSelecionada" />
							<button class="ml-3" v-if="$epecaHubB2c.featuresJson.SELLER" @click="triggerProfile()">
								<img v-if="!autenticado" src="/custom/loja/assets/media/icons/profile-inactive.svg" alt="Perfil Inativo" />
								<img v-else src="/custom/loja/assets/media/icons/profile-active.svg" alt="Perfil Ativo" />
							</button>

							<button class="ml-3" v-if="$epecaHubB2c.featuresJson.SELLER" @click="$router.push({ path: '/checkout/carrinho' })" style="display: flex; align-items: center">
								<img src="/custom/loja/assets/media/icons/cart.svg" alt="Carrinho" />
								<span id="notification-header" class="ml-1 quantidade-produtos notification-bubble font-12" :class="{ expanded: quantidadeProdutosAdicionados > 9 }">{{ quantidadeProdutosAdicionados }}</span>
							</button>
						</div>
					</div>
				</div>
				<div class="logo">
					<img @click="$router.push({ path: '/' })" src="/custom/loja/assets/media/icons/logo.svg" alt="Logo" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Localizacao from "@/components/Common/Localizacao"
import LocationPopup from "@/components/Common/LocationPopup"
import Submenu from "@/components/Common/Submenu"
export default {
	components: {
		Submenu,
		LocationPopup,
		Localizacao,
	},
	computed: {
		quantidadeProdutosAdicionados() {
			return this.$store.getters["Carrinho/quantidadeProdutosAdicionados"]
		},
		currentRouteName() {
			return this.$route.name
		},
	},
	methods: {
		botaoMinhaLocalizacaoClick() {
			this.$emit("botaoMinhaLocalizacaoClick")
		},
		triggerProfile() {
			if (!this.autenticado) {
				this.$emit("botaoRedirecionarLogin")
			} else {
				this.$router.replace({ path: "/minha-conta/meus-dados" })
			}
		},
	},
	props: {
		autenticado: {
			type: Boolean,
		},
		selecionouCidade: {
			type: Boolean,
		},
		cidadeSelecionada: {
			type: String,
		},
		submenuConfig: {
			type: Array,
		},
	},
}
</script>

<style scoped lang="scss">
$theme-color: #001e50;

$fontVwHeadBold: "vwhead-bold", sans-serif;
$fontVwHeadLight: "vwhead-light", sans-serif;
$fontVwTextBold: "vwtext-bold", sans-serif;
$fontVwTextRegular: "vwtext-regular", sans-serif;
.vw__v2 {
	.header__wrapper {
		transition: all 0.3s ease-in-out;
		position: fixed;
		width: 100%;
		color: #fff;
		&.header__sticky {
			z-index: 999;
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			.header__vw {
				box-shadow: 0 0 25px 2px rgb(0 0 0 / 15%);
				&__logo {
					svg {
						fill: $theme-color;
					}
					&::before {
						background-color: $theme-color;
					}
					&::after {
						background-color: $theme-color;
					}
				}
			}
			.botao-carrinho {
				svg {
					fill: $theme-color;
				}
			}
		}
		.botao-carrinho {
			margin-left: 12px;
			display: flex;
			align-items: center;
			svg {
				height: 34px;
				width: 34px;
				margin-top: -2px;
				fill: #fff;
			}
		}
	}
	.header__vw {
		position: fixed;
		color: #fff;
		top: 0;
		left: 0;
		font-size: 14px;
		width: 100%;
		&__bg {
			position: absolute;
			top: 0px;
			height: 116px;
			width: 100%;
			background: linear-gradient(rgba(0, 0, 0, 0.6) 40%, rgba(0, 0, 0, 0));
			opacity: 1;
		}
		&__menu {
			height: 116px;
			width: 100%;
			max-width: 1220px;
			margin: 0 auto;
			display: grid;
			grid-template-columns: 2fr 1fr;
			z-index: 2;
			.left-side {
				display: grid;
				grid-gap: 10px;
				align-items: center;
				justify-content: flex-start;
			}
			.right-side {
				display: grid;
				grid-gap: 10px;
				align-items: center;
				justify-content: flex-end;
				font-size: 0.9rem;
				text-align: right;
				font-family: $fontVwHeadBold;
			}
		}
		&__logo {
			position: absolute;
			display: grid;
			width: 100%;
			align-items: center;
			grid-template-columns: calc(66.66vw - 14px) max-content auto;
			grid-gap: 14px;
			height: 116px;
			&::before {
				content: "";
				height: 2px;
				background: rgb(255, 255, 255);
			}
			&::after {
				content: "";
				height: 2px;
				background: rgb(255, 255, 255);
			}
		}
	}
}

.header__ktm {
	background: var(--header-bg-color);
	padding: 0 16px;
	@media (min-width: 768px) {
		padding: 0;
	}
}
.right-down {
	display: flex;
	text-align: right;
	i {
		margin-right: 6px;
		font-size: 18px;
	}
}

@media (min-width: 768px) and (max-width: 1199px) {
	.moving__frame__bottom {
		max-width: 90vw;
		margin-top: 0;
	}
	.moving__frame__top {
		max-width: 90vw;
		margin-bottom: 0;
	}
}
@media (max-width: 767px) {
	.moving__frame__top {
		margin-bottom: 0;
		padding: 0 16px;
		width: 100%;
		grid-template-columns: 1fr;
	}
}
.header-height {
	height: 120px;
}
.header__grid {
	width: 100%;
	height: 105px;
	@media (min-width: 768px) {
		max-width: 100%;
		width: 90vw;
		margin: 0 auto;
	}
	@media (min-width: 1220px) {
		width: 100%;
		max-width: 1220px;
		display: flex;
		margin: 0 auto;
	}
}
.icon-close {
	path {
		fill: var(--icon-close-color);
	}
}
.notification-bubble {
	background-color: var(--notification-bubble-bg-color);
	color: var(--notification-bubble-text-color);
}
.actions {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: relative;
	height: 100%;
	@media (min-width: 1024px) {
		min-width: 400px;
	}
	@media (min-width: 1220px) {
		min-width: 500px;
	}
}
.logo {
	background-color: var(--logo-bg);
	width: 128px;
	margin-left: 16px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		max-width: 70%;
		cursor: pointer;
	}
	@media (min-width: 768px) {
		width: 192px;
	}
}
</style>
