<template>
	<div class="vw__v2">
		<HeaderVW
			v-if="cliente === 'VW'"
			@botaoLogout="botaoLogout"
			@botaoRedirecionarLogin="botaoRedirecionarLogin"
			@botaoRedirecionarMinhaConta="botaoRedirecionarMinhaConta"
			@botaoDesselecionarCidadeClick="botaoDesselecionarCidadeClick"
			@botaoMinhaLocalizacaoClick="botaoMinhaLocalizacaoClick"
			:autenticado="autenticado"
			:selecionouCidade="selecionouCidade"
			:cidadeSelecionada="cidadeSelecionada"
			@newSearch="newSearch"
			:submenuConfig="submenuConfig"
			:ALGOLIA_INDEX_PRODUCTS="ALGOLIA_INDEX_PRODUCTS"
		></HeaderVW>

		<HeaderKTM
			v-else
			@botaoLogout="botaoLogout"
			@botaoRedirecionarLogin="botaoRedirecionarLogin"
			@botaoRedirecionarMinhaConta="botaoRedirecionarMinhaConta"
			@botaoDesselecionarCidadeClick="botaoDesselecionarCidadeClick"
			@botaoMinhaLocalizacaoClick="botaoMinhaLocalizacaoClick"
			:autenticado="autenticado"
			:selecionouCidade="selecionouCidade"
			:cidadeSelecionada="cidadeSelecionada"
			@newSearch="newSearch"
			:submenuConfig="submenuConfig"
			:ALGOLIA_INDEX_PRODUCTS="ALGOLIA_INDEX_PRODUCTS"
		></HeaderKTM>

		<Whatsapp />

		<section id="layout-default">
			<main class="main__v3 header-float dynamic-mt-28">
				<nuxt />
			</main>

			<client-only>
				<component-minha-localizacao class="component-minha-localizacao" @confirmou-nova-cidade="componentMinhaLocalizacaoConfirmouNovaCidade" :config-minha-localizacao="configMinhaLocalizacao" v-if="$epecaHubB2c.featuresJson.GEOLOCATION_SELECT" />
			</client-only>

			<ComponentLGPD class="component-lgpd" :config="componentLgpdConfig" />
		</section>

		<div class="footer__wrapper">
			<div v-if="$route.name !== 'index'" class="container mx-auto" style="max-width: 1220px">
				<hr />
			</div>
			<div class="descricao__home py-8 px-4 md:px-0" v-else>
				<div class="container mx-auto" style="max-width: 1220px">
					<h6 class="mb-4">{{ footerValues.description.title }}</h6>
					<p>{{ footerValues.description.text }}</p>
				</div>
			</div>
			<Footer :values="footerValues" />
		</div>

		<!-- <NotificationTooltip :visibility="notificationTooltip">
			<div style="display: flex; gap: 4px; align-items: center">Detectamos a sua localização automaticamente. Caso esteja errada, troque seu CEP.</div>
		</NotificationTooltip> -->
	</div>
</template>

<script>
import axios from "axios"
import NotificationTooltip from "@/components/Common/NotificationTooltip"
import Whatsapp from "@/components/atoms/Whatsapp"
import ComponentLGPD from "~/components/ComponentLGPD"
import HeaderVW from "@/components/Common/HeaderVW"
import HeaderKTM from "@/components/Common/HeaderKTM"

let API_B2C_BACKEND = process.env.API_B2C_BACKEND
let ALGOLIA_INDEX_PRODUCTS = process.env.ALGOLIA_INDEX_PRODUCTS
let TOKEN_EPA = process.env.TOKEN_EPA

export default {
	components: {
		Whatsapp,
		HeaderVW,
		HeaderKTM,
		NotificationTooltip,
		ComponentLGPD,
		"component-minha-localizacao": () => {
			if (process.client) {
				return import("../components/ComponentMinhaLocalizacao.vue")
			}
		},
	},
	data() {
		return {
			notificationTooltip: false,
			query: "",
			searchClient: null,
			componentLgpdConfig: {
				keyCookie: "epeca-hub-b2c/politicas-de-privacidade/aceitar-cookies",
				botaoConfirm: "Continuar",
				filesPrivacyPolicy: this.$epecaHubB2c.configJson.FILES_PRIVACY_POLICY,
				textCookie: this.$epecaHubB2c.configJson.LGPD_TEXT_COOKIE,
				linkPrivacyPolicy: this.$epecaHubB2c.configJson.LGPD_TEXT_COOKIE_LINK,
			},
			ALGOLIA_INDEX_PRODUCTS: ALGOLIA_INDEX_PRODUCTS,

			configMinhaLocalizacao: {
				callbackAposSelecionarCidade: null,
				exibir: false,
				endpoint: {
					getTodasCidades: `${API_B2C_BACKEND}/geolocalizacao/cidades`,
					postLocalidadeViaCEP: `${API_B2C_BACKEND}/geolocalizacao?token=${TOKEN_EPA}`,
					postLocalidadePorLatLong: `${API_B2C_BACKEND}/geolocalizacao?token=${TOKEN_EPA}`,
				},
			},

			headerFloat: false,
			aviseMeModalVisibility: false,

			modalConfirmCepBloqueado: {
				exibir: false,
			},

			termoBusca: "",
		}
	},
	computed: {
		cliente() {
			return process.env.envName
		},
		lastSearch() {
			return this.$store.getters["lastSearch"]
		},
		usuario() {
			let objUsuario = this.$store.getters["usuario"]
			let usuario = objUsuario.nome_razao.split(" ")[0]
			return usuario
		},
		footerValues() {
			return this.$epecaHubB2c.configJson.FOOTER_VALUES
		},
		autenticado() {
			return this.$store.getters["autenticado"]
		},
		cidadeSelecionada() {
			return this.$store.getters["cidadeSelecionada"]
		},

		concessionariaSelecionada() {
			return this.$store.getters["concessionariaSelecionada"]
		},

		selecionouCidade() {
			return this.$store.getters["selecionouCidade"]
		},

		selecionouConcessionaria() {
			return this.$store.getters["selecionouConcessionaria"]
		},

		submenuConfig() {
			const configJson = this.$epecaHubB2c.configJson
			const submenu = configJson.SUBMENU || null

			return submenu
		},

		veiculoSelecionado() {
			return this.$store.getters["veiculoSelecionado"]
		},

		veiculoSelecionadoNome() {
			return this.$store.getters["veiculoSelecionadoNome"]
		},
	},
	methods: {
		async getUserIp() {
			try {
				const res = await axios.get("https://api.ipify.org?format=json")
				return res.data.ip
			} catch (error) {
				console.log(error)
			}
		},
		async getLatLonIp(userIp) {
			try {
				const res = await axios.get(`https://ipapi.co/${userIp}/json/`)
				return { lat: res.data.latitude, lon: res.data.longitude }
			} catch (error) {
				console.log(error)
			}
		},
		async getCidadeByLatLon(latlon) {
			try {
				let payload = {
					lat: latlon.lat,
					long: latlon.lon,
				}
				const res = await axios.post(`${API_B2C_BACKEND}/geolocalizacao?token=${TOKEN_EPA}`, payload)
				return res.data.data
			} catch (error) {
				console.log(error)
			}
		},
		newSearch(text) {
			this.termoBusca = text
			this.buscar()
		},
		buscar() {
			this.$store.dispatch("setLastSearch", this.termoBusca)
			this.$router.push({ path: "/lista/", query: { query: this.termoBusca } })
		},
		botaoDesselecionarCidadeClick(e) {
			this.$store.dispatch("setStoreIds", [])
			this.$store.dispatch("definirCep", false)
			this.$store.dispatch("definirCidadeSelecionada", false)
			this.$store.dispatch("definirConcessionariaSelecionada", false)
			this.$storage.removeUniversal("geolocation")
		},
		botaoMinhaLocalizacaoClick(e) {
			this.configMinhaLocalizacao.exibir = true
		},
		botaoRedirecionarLogin(e) {
			this.$nuxt.$router.push({
				path: `/usuario/login`,
			})
		},
		botaoRedirecionarMinhaConta(e) {
			this.$nuxt.$router.push({
				path: `/minha-conta/meus-dados`,
			})
		},
		botaoRedirecionarMeusPedidos(e) {
			this.$nuxt.$router.push({
				path: `/minha-conta/pedidos`,
			})
		},
		botaoLogout(e) {
			this.$store.dispatch("logout")
			this.$nuxt.$router.push({
				path: `/usuario/login`,
			})
		},
		async componentMinhaLocalizacaoConfirmouNovaCidade(obj) {
			await this.$store.dispatch("definirCep", obj.cep)
			await this.$store.dispatch("definirCidadeSelecionada", obj.cidade)

			if (this.configMinhaLocalizacao.callback) {
				this.configMinhaLocalizacao.callback()
				this.configMinhaLocalizacao.callback = null
			}
		},
		verificarAutenticado() {
			const lsToken = localStorage.getItem("epeca-hub-b2c/token")
			const cookiesToken = this.$cookies.get("epeca-hub-b2c/token")
			if (lsToken && cookiesToken && lsToken === cookiesToken) {
				this.$store.dispatch("carregarUsuario").then(() => {
					this.$store.dispatch("definirAutenticado", true)
				})
			}
		},
	},
	watch: {
		$route: {
			handler: function (value, oldValue) {
				if (process.client) {
					if (process.env.TOKEN_EPA) {
						let $epa = new window._epa({
							cKey: process.env.TOKEN_EPA,
						})
						$epa.send("pageview")
					}
				}

				if (this.$gtmHelpers) {
					this.$gtmHelpers.sendEvent.basic({
						event: "pageview",
						pagePath: value.fullPath,
						pageType: value.name === "index" ? "home" : value.name,
					})
				}

				if (this.$helpers) {
					this.$helpers.verificarAutenticado({ app: this, routeTo: value })
				}
			},
			immediate: true,
		},
	},
	created() {
		this.$eventbus.$on("ComponentMinhaLocalizacao/abrir", (obj) => {
			this.configMinhaLocalizacao.exibir = true

			if (obj) {
				if (obj.callback) {
					this.configMinhaLocalizacao.callback = obj.callback
				}
			}
		})

		this.$eventbus.$on("ComponentMinhaLocalizacao/fechar", () => {
			this.configMinhaLocalizacao.exibir = false
		})

		this.$eventbus.$on("ModalConfirmCepBloqueado/abrir", (obj) => {
			this.modalConfirmCepBloqueado.exibir = true
		})

		this.$eventbus.$on("ModalConfirmCepBloqueado/fechar", () => {
			this.modalConfirmCepBloqueado.exibir = false
		})

		if (process.client) {
			window.addEventListener(
				"scroll",
				(e) => {
					if (this.$mq.match(/md|lg/)) {
						this.headerFloat = true
					}
				},
				false
			)
		}
	}, //created
	async beforeMount() {
		if (process.client) {
			const lsCep = localStorage.getItem("epeca-hub-b2c/cep")
			let geolocation = this.$cookies.get("geolocation")
			const availableStores = localStorage.getItem("epeca-hub-b2c/availableStores")
			const lsConcessionaria = localStorage.getItem("epeca-hub-b2c/concessionaria")
			const lsVeiculoSelecionado = sessionStorage.getItem("epeca-hub-b2c/veiculo-selecionado")

			if (availableStores) {
				let arrAvailableStores = availableStores.split(",")
				this.$store.dispatch("setStoreIds", arrAvailableStores)
			}

			const parseConcessionaria = lsConcessionaria ? JSON.parse(lsConcessionaria) : null
			const parseVeiculoSelecionado = lsVeiculoSelecionado ? JSON.parse(lsVeiculoSelecionado) : null

			this.$store.dispatch("definirCep", lsCep)
			this.$store.dispatch("definirCidadeSelecionada", geolocation ? geolocation.cidade : "")
			this.$store.dispatch("definirConcessionariaSelecionada", parseConcessionaria)
			this.$store.dispatch("definirVeiculoSelecionado", parseVeiculoSelecionado)
			this.$store.dispatch("Carrinho/carregarCarrinhoStorage")

			if (!geolocation) {
				const ip = await this.getUserIp()
				const userLatLon = await this.getLatLonIp(ip)
				const userIpData = await this.getCidadeByLatLon(userLatLon)

				if (userLatLon && userIpData) {
					// Insere os Ids das Lojas recebidos do BE (availableStores)
					this.$store.dispatch("setStoreIds", userIpData.lojas_prioritarias)
					this.$storage.setUniversal("geolocation", userIpData)
					this.$store.dispatch("definirCep", userIpData.cep)
					this.$store.dispatch("definirCidadeSelecionada", userIpData.cidade)
				}
			}
		}

		this.$helpers.verificarAutenticado({ app: this })
	},
	mounted() {
		this.verificarAutenticado()

		if (process.client) {
			window.dataLayer = window.dataLayer || []

			window.dataLayer.push({
				originalLocation: document.location.protocol + "//" + document.location.hostname + document.location.pathname + document.location.search,
			})
		}
	},
	head() {
		const metaTags = this.$epecaHubB2c.configJson.META_TAGS ? this.$epecaHubB2c.configJson.META_TAGS : []

		let script = []

		if (this.$epecaHubB2c.configJson.JIVO_ID) {
			script.push({
				async: true,
				src: `//code.jivosite.com/widget/${this.$epecaHubB2c.configJson.JIVO_ID}`,
			})
		}

		return {
			meta: [...metaTags],
			script,
			title: this.$epecaHubB2c.configJson.SEO_DEFAULT_TITLE,
		}
	},
}
</script>
<style lang="scss">
button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	font-family: inherit;
	font-size: 1em;
	outline: none;
	padding: 0;
}

input {
	font-family: inherit;
	font-size: 1em;
	outline: none;
}
.footer__wrapper {
	margin-top: 56px;
	.logo {
		img {
			height: 200px;
		}
	}
}
@media (min-width: 1200px) {
	.footer__wrapper {
		margin-top: 96px;
	}
}
</style>

<style lang="scss" scoped>
.header {
	align-items: center;
	display: flex;
	width: 100%;
	@media (max-width: 991px) {
		display: block;
	}
}

#layout-default {
	margin: 0 auto;
	position: relative;
}

header.header-float {
	background-color: #fff;
	box-sizing: border-box;
	position: fixed;
	top: 0;
	width: inherit;
	z-index: 1000;
}

header > div {
	display: flex;
	align-items: center;
	justify-content: space-between;

	box-sizing: border-box;
	margin: 0 auto;
	width: 100%;
}

header > div:first-child {
	display: flex;
	align-items: center;

	padding: 1.2rem 0;
}

main {
	margin-top: 24px;
}
.ktm main {
	padding-top: 104px;
}

.component-lgpd#component-lgpd ::v-deep > div {
	background-color: #fff;
	color: #000;
	box-shadow: 0 0 25px 2px rgb(0 0 0 / 15%);
	border-radius: 0;
	box-sizing: border-box;
	z-index: 9;
}

.component-lgpd#component-lgpd ::v-deep .box-flutuante .btn-continuar {
	background-color: #fff;
	border-radius: 0;
	color: #111;
	margin-left: 0.5em;
}

@media (max-width: 767px) {
	.component-lgpd#component-lgpd ::v-deep .box-flutuante .btn-continuar {
		width: 100%;
	}

	footer .container-row-copyright {
		display: block;
		padding: 1.25rem;
	}

	footer .container-row-copyright > span {
		display: block;
		margin-bottom: 1em;
	}
}

@media (max-width: 991px) {
	header > div:first-child {
		padding: 1.2rem;
	}
}

@media (min-width: 1024px) and (min-width: 1199px) {
	#layout-default {
		width: 90vw;
	}
}

@media (min-width: 1200px) {
	#layout-default {
		width: 1220px;
	}
}
.descricao__home {
	background: #f1f1f1;
	color: #3c484d;
	h6 {
		font-size: 16px;
		line-height: 19px;
		@media (min-width: 768px) {
			font-size: 24px;
			line-height: 29px;
		}
	}
	p {
		font-size: 12px;
		line-height: 15px;
		@media (min-width: 768px) {
			font-size: 16px;
			line-height: 19px;
		}
	}
}
</style>
