import { render, staticRenderFns } from "./NotificationTooltip.vue?vue&type=template&id=65e9b8d1&scoped=true&"
import script from "./NotificationTooltip.vue?vue&type=script&lang=js&"
export * from "./NotificationTooltip.vue?vue&type=script&lang=js&"
import style0 from "./NotificationTooltip.vue?vue&type=style&index=0&id=65e9b8d1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65e9b8d1",
  null
  
)

export default component.exports