import { setAvailableStores, checkProductAvailability } from "@/utils/availability"

export default async ({ app }, inject) => {
	if (process.client) {
		const gtmHelpers = {
			getProductsForGtm: (obj) => {
				const mostrarPreco = app.store.getters["mostrarPreco"]

				let arrProdutosGtm = []
				let arrProdutosOrigem = null

				if (obj.products && obj.products.product) {
					arrProdutosOrigem = [obj.products.product]
				} else if (obj.products && obj.products.lists) {
					arrProdutosOrigem = obj.products.lists.listaProdutos
				}

				if (arrProdutosOrigem) {
					arrProdutosOrigem.forEach((item, index) => {
						const original_code = item.codigosoriginais.length > 0 ? item.codigosoriginais[0] : ""

						let categories = []
						let position = index + 1

						if (item.categoria) {
							item.categoria.map((itemCategoria) => {
								let categoriesItem = ""

								if (itemCategoria.departamento) {
									categoriesItem += itemCategoria.departamento
								}

								if (itemCategoria.secao) {
									categoriesItem += " / " + itemCategoria.secao
								}

								if (itemCategoria.grupo) {
									categoriesItem += " / " + itemCategoria.grupo
								}

								if (itemCategoria.subgrupo) {
									categoriesItem += " / " + itemCategoria.subgrupo
								}

								categories.push(categoriesItem)
							})
						}

						let objProdutoGtm = {
							id: item.id_produto_variacao,
							name: item.descricao,
							description: item.comentario,
							categories,
							original_code,
							sales_unit: item.unidade_venda,
						}

						objProdutoGtm["availability"] = 0
						objProdutoGtm["total_value"] = 0
						objProdutoGtm["sale_value"] = 0

						if (obj.event === "checkout") {
							objProdutoGtm["sale_value"] = item.preco_low
						}

						if (obj.event === "productDetail") {
							objProdutoGtm["sale_value"] = item.preco_low
						}

						if (obj.options) {
							if (obj.options.position) {
								position = obj.options.position
							}

							if (obj.options.page && obj.options.hitsPerPage) {
								const page = obj.options.page
								const offset = page * obj.options.hitsPerPage + position

								position = offset
							}
						}

						if (mostrarPreco) {
							let defaultStoreId = []

							if (app.$epecaHubB2c.configJson.DEFAULT_STORE_ID) {
								defaultStoreId = app.$epecaHubB2c.configJson.DEFAULT_STORE_ID.split(",")
							}

							objProdutoGtm["quantity"] = 1
							objProdutoGtm["total_value"] = item.preco_low
							objProdutoGtm["sale_value"] = item.preco_low
						}

						if (obj.event === "purchase") {
							objProdutoGtm["quantity"] = item.quantity
							objProdutoGtm["total_value"] = item.price
							objProdutoGtm["sale_value"] = item.price
							objProdutoGtm["id"] = item.item_id
							objProdutoGtm["name"] = item.item_name
							objProdutoGtm["description"] = item.description
						}

						objProdutoGtm["position"] = position

						arrProdutosGtm.push(objProdutoGtm)
					})

					return arrProdutosGtm
				}
			},

			sendEvent: {
				basic: (obj) => {
					app.$gtm.push(obj)
				},

				withProducts: (obj) => {
					const products = gtmHelpers.getProductsForGtm(obj)

					delete obj.listaPrecoEstoque
					delete obj.listaProdutos

					if (obj.options) {
						if (obj.options.checkoutStep) {
							obj["checkoutStep"] = obj.options.checkoutStep
						}

						if (obj.options.checkoutName) {
							obj["checkoutName"] = obj.options.checkoutName
						}

						if (obj.options.transactionId) {
							obj["transactionId"] = obj.options.transactionId
						}

						if (obj.options.transactionValue) {
							obj["transactionValue"] = obj.options.transactionValue
						}

						if (obj.options.shipping) {
							obj["shipping"] = obj.options.shipping
						}

						delete obj.options
					}

					if (!obj.items) {
						obj.products = products
					}

					app.$gtm.push(obj)
				},
			},
		}

		inject("gtmHelpers", gtmHelpers)
	}
}
