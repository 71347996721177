import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _735ebc86 = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _9c561e72 = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _c1566160 = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _31358e2e = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _58bba680 = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _f8556866 = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _41f0b883 = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _396d80ce = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _8220fafc = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _5bedc8f2 = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _26091e74 = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _6a9f8814 = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _0ea996a2 = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _f6a675e0 = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _25f456cb = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _5fb92eac = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _9ef7538a = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _2e4d88e9 = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/mapa-do-site",
    component: _735ebc86,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _9c561e72,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _c1566160,
    name: "motos"
  }, {
    path: "/reset",
    component: _31358e2e,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _58bba680,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _f8556866,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _41f0b883,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _396d80ce,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _8220fafc,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _5bedc8f2,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _26091e74,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _6a9f8814,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _0ea996a2,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _f6a675e0,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _25f456cb,
    name: "produto-id-all"
  }, {
    path: "/lista/*",
    component: _5fb92eac,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _9ef7538a,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _2e4d88e9,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
