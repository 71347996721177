import Vue from "vue"

const axios = require("axios")
const lodash = require("lodash")

let API_B2C_BACKEND = process.env.API_B2C_BACKEND

export const state = () => ({
	carrinho: {},
})

export const getters = {
	carrinho: (state) => {
		return state.carrinho
	},

	quantidadeProdutosAdicionados: (state) => {
		let quantidade = 0

		Object.keys(state.carrinho).map((value, index, array) => {
			quantidade += state.carrinho[value].quantidade
		})

		return quantidade
	},
}

export const actions = {
	adicionarProduto(context, obj) {
		const carrinho = lodash.cloneDeep(context.getters["carrinho"])
		const produto = obj

		const idProdutoVariacao = produto.id_produto_variacao
		const idLoja = produto.id_loja

		if (carrinho[idProdutoVariacao + "-" + idLoja]) {
			const quantidade = carrinho[idProdutoVariacao + "-" + idLoja]["quantidade"]

			carrinho[idProdutoVariacao + "-" + idLoja]["quantidade"] = quantidade + 1
		} else {
			carrinho[idProdutoVariacao + "-" + idLoja] = produto

			carrinho[idProdutoVariacao + "-" + idLoja]["quantidade"] = 1
		}

		context.dispatch("definirCarrinho", carrinho)
	},

	buscarFrete(context, obj) {
		const cepDestino = obj.cepDestino
		const produtosCarrinho = obj.produtosCarrinho
		const url = obj.url

		return new Promise((resolve, reject) => {
			const produtos = []

			//Monta o array de produtos de acordo com o que a API espera
			Object.values(produtosCarrinho).forEach((item, index, array) => {
				produtos.push({
					id: String(item.id),
					id_produto_variacao: String(item.id_produto_variacao),
					preco_final: item.valor,
					quantidade: item.quantidade,
					codigo_original: item.codigo_original,
					codigosoriginais: item.codigosoriginais,
					id_loja: item.id_loja,
				})
			})

			axios
				.post(API_B2C_BACKEND + "/frete/v3", {
					cep_destino: cepDestino,
					url: url,
					produtos,
				})
				.then((response) => {
					resolve(response)
				})
				.catch((error) => {
					reject(error)
				})
		})
	},

	carregarCarrinhoStorage(context, obj) {
		if (process.client) {
			const storageCarrinho = localStorage.getItem("epeca-hub-b2c/carrinho")

			let carrinho = {}

			if (storageCarrinho) {
				carrinho = JSON.parse(storageCarrinho)
			}

			context.dispatch("definirCarrinho", carrinho)
		}
	},

	definirCarrinho(context, obj) {
		localStorage.setItem("epeca-hub-b2c/carrinho", JSON.stringify(obj))

		context.commit("DEFINIR_CARRINHO", obj)
	},

	definirQuantidade(context, obj) {
		let carrinho = {}
		const idProdutoVariacao = obj.id_produto_variacao
		const idLoja = obj.id_loja
		const idProduto = idProdutoVariacao + "-" + idLoja

		context.commit("DEFINIR_QUANTIDADE", idProduto)

		carrinho = context.getters.carrinho

		context.dispatch("definirCarrinho", carrinho)
	},

	esvaziarCarrinho(context) {
		localStorage.removeItem("epeca-hub-b2c/carrinho")

		context.commit("ESVAZIAR_CARRINHO")
	},

	quantidadeMais(context, obj) {
		let carrinho = {}
		const idProdutoVariacao = obj.id_produto_variacao
		const idLoja = obj.id_loja
		const idProduto = idProdutoVariacao + "-" + idLoja
		const quantity = obj.quantidade
		let productDetails = {}
		productDetails.idProduto = idProduto
		productDetails.quantity = quantity

		context.commit("QUANTIDADE_MAIS", productDetails)

		carrinho = context.getters.carrinho

		context.dispatch("definirCarrinho", carrinho)
	},

	quantidadeMenos(context, obj) {
		let carrinho = {}
		const idProdutoVariacao = obj.id_produto_variacao
		const idLoja = obj.id_loja
		const idProduto = idProdutoVariacao + "-" + idLoja
		const quantity = obj.quantidade
		let productDetails = {}
		productDetails.idProduto = idProduto
		productDetails.quantity = quantity

		context.commit("QUANTIDADE_MENOS", productDetails)

		carrinho = context.getters.carrinho

		if (Object.keys(carrinho).length < 1) {
			localStorage.removeItem("epeca-hub-b2c/carrinho")
		} else {
			context.dispatch("definirCarrinho", carrinho)
		}
	},

	removerProduto(context, obj) {
		let product = obj
		let productPrice = product.preco_desconto
		let itemDetails = {
			item_id: product.id_produto_variacao,
			item_name: product.descricao,
			coupon: "",
			discount: 0,
			item_brand: "",
			item_category: "",
			item_variant: "",
			price: productPrice,
			currency: "BRL",
			quantity: product.quantidade,
		}

		if (process.env.TOKEN_EPA) {
			let $epa = new window._epa({
				cKey: process.env.TOKEN_EPA,
			})

			$epa.send("remove_from_cart", {
				currency: "BRL",
				value: productPrice,
				items: [itemDetails],
			})
		}

		let carrinho = {}
		let stringCarrinho = ""

		const idProdutoVariacao = obj.id_produto_variacao
		const idLoja = obj.id_loja
		const idProduto = idProdutoVariacao + "-" + idLoja

		localStorage.setItem("epeca-hub-b2c/carrinho", stringCarrinho)

		context.commit("REMOVER_PRODUTO", idProduto)

		carrinho = context.getters.carrinho

		context.dispatch("definirCarrinho", carrinho)
	},
}

export const mutations = {
	DEFINIR_CARRINHO: (state, obj) => {
		state.carrinho = obj
	},

	ESVAZIAR_CARRINHO(state) {
		state.carrinho = {}
	},

	DEFINIR_QUANTIDADE(state, idProduto) {
		Vue.set(state.carrinho[idProduto], "quantidade", state.carrinho[idProduto].quantidade + 1)
	},

	QUANTIDADE_MAIS(state, productDetails) {
		Vue.set(state.carrinho[productDetails.idProduto], "quantidade", productDetails.quantity)
	},

	QUANTIDADE_MENOS(state, productDetails) {
		Vue.set(state.carrinho[productDetails.idProduto], "quantidade", productDetails.quantity)
	},

	REMOVER_PRODUTO(state, idProduto) {
		Vue.delete(state.carrinho, idProduto)
	},
}
