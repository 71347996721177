<template>
  <div class="whatsapp" v-if="whatsAppNumber">
    <a
      :href="`https://api.whatsapp.com/send?phone=${whatsAppNumber}&text=`"
      target="_blank"
    >
      <svg
        width="83"
        height="84"
        viewBox="0 0 83 84"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_552:2649)">
          <path
            d="M7.22681 73.942L12.0587 56.299C9.07771 51.1355 7.5101 45.2774 7.51198 39.2777C7.51979 20.5052 22.7978 5.23242 41.5696 5.23242C50.6805 5.23654 59.2316 8.7823 65.6609 15.2177C72.0916 21.6531 75.6309 30.2071 75.6272 39.3043C75.6194 58.0773 60.3405 73.3514 41.571 73.3514C41.5696 73.3514 41.5719 73.3514 41.571 73.3514H41.5558C35.8561 73.3496 30.2556 71.9188 25.2814 69.2078L7.22681 73.942Z"
            fill="url(#paint0_linear_552:2649)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M32.7582 24.523C32.0979 23.055 31.4027 23.0256 30.7746 22.9999C30.2608 22.9779 29.6725 22.9792 29.0853 22.9792C28.4975 22.9792 27.5425 23.2001 26.7348 24.0822C25.9267 24.9643 23.6492 27.0967 23.6492 31.4336C23.6492 35.771 26.8083 39.9619 27.2486 40.5506C27.6894 41.1383 33.3469 50.3228 42.3066 53.8562C49.753 56.7926 51.2683 56.2085 52.8846 56.0616C54.5008 55.9146 58.0998 53.9296 58.8345 51.8711C59.5691 49.813 59.5691 48.0489 59.3487 47.6802C59.1283 47.3128 58.5406 47.0924 57.659 46.6516C56.7774 46.2108 52.4438 44.0779 51.6356 43.7841C50.8275 43.4902 50.2397 43.3432 49.652 44.2258C49.0643 45.1074 47.3759 47.0924 46.8616 47.6802C46.3473 48.2693 45.8331 48.3427 44.9515 47.9019C44.0698 47.4598 41.2308 46.5299 37.8628 43.5268C35.2423 41.1906 33.4731 38.3051 32.9588 37.4226C32.4446 36.5409 32.9037 36.0634 33.3459 35.6239C33.7417 35.229 34.2275 34.5949 34.6683 34.0802C35.1082 33.5654 35.2551 33.1981 35.549 32.6103C35.8429 32.0216 35.6959 31.5069 35.4755 31.0661C35.2551 30.6253 33.542 26.266 32.7582 24.523Z"
            fill="white"
          />
          <path
            d="M66.5311 14.343C59.8699 7.67712 51.0125 4.00417 41.5756 4C22.1293 4 6.30398 19.8205 6.29616 39.2662C6.29387 45.4822 7.91797 51.5497 11.0049 56.8982L6 75.1735L24.702 70.2694C29.8548 73.0796 35.6565 74.5604 41.5605 74.5627H41.5755H41.5756C61.0187 74.5627 76.8464 58.7394 76.8547 39.2946C76.8579 29.8705 73.1914 21.0094 66.5311 14.343ZM41.5756 68.6063H41.5642C36.3016 68.6036 31.141 67.1907 26.6397 64.5197L25.5685 63.8846L14.4703 66.7944L17.4333 55.9776L16.7354 54.8687C13.7999 50.2011 12.2502 44.8071 12.2525 39.2686C12.2589 23.106 25.4124 9.95694 41.5867 9.95694C49.4192 9.96017 56.7807 13.0132 62.3173 18.5537C67.8535 24.0941 70.9002 31.4593 70.8979 39.2925C70.8909 55.4555 57.737 68.6063 41.5756 68.6063Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_552:2649"
            x="0"
            y="0"
            width="82.8547"
            height="83.1735"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="3" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_552:2649"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_552:2649"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_552:2649"
            x1="41.427"
            y1="73.942"
            x2="41.427"
            y2="5.23254"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#20B038" />
            <stop offset="1" stop-color="#60D66A" />
          </linearGradient>
        </defs>
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  computed: {
    whatsAppNumber() {
      return this.$epecaHubB2c.configJson.WHATSAPP_NUMBER;
    }
  },
};
</script>

<style scoped>
.whatsapp {
  position: fixed;
  width: 70px;
  height: 70px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 1px 1px 2px #888;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.whatsapp a {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.whatsapp svg {
  height: 40px;
  width: 40px;
}
@media (max-width: 767px) {
  .whatsapp {
    right: 16px;
    bottom: 90px;
    height: 60px;
    width: 60px;
  }
  .whatsapp a {
    height: 60px;
    width: 60px;
  }
}
</style>
