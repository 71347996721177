<template>
	<div>
		<button class="btn__location flex align-items-center" @click="$emit('botaoMinhaLocalizacaoClick')">
			<client-only>
				<div class="tooltip" v-if="notificationVisibility && !selecionouCidade">
					<span class="tooltiptext head-regular flex gap-2 items-start md:items-center md:justify-center"
						>Para ver as melhores ofertas e prazos para sua região informe sua localização.
						<img @click.stop.prevent="notificationVisibility = false" src="/custom/loja/assets/media/icons/close-white.svg" alt="Fechar" width="12" height="12" />
					</span>
				</div>

				<!-- Não selecionou a cidade -->
				<div class="flex gap-1" v-if="!selecionouCidade">
					<img src="/custom/loja/assets/media/icons/localizacao-inactive.svg" alt="Sem localização" />
					<span class="head-light cep-text cep-text-color">Informe seu CEP</span>
				</div>

				<!-- Selecionou a cidade -->
				<div class="flex gap-1" v-else>
					<img src="/custom/loja/assets/media/icons/localizacao-active.svg" alt="Localização" />
					<span class="head-light cep-text-color local-text md:hidden">{{ truncateString(cidadeSelecionada, 20) }}</span>
					<span class="head-light cep-text-color hidden md:block">{{ truncateString(cidadeSelecionada, 30) }}</span>
				</div>
			</client-only>
		</button>
	</div>
</template>

<script>
export default {
	data() {
		return {
			timeoutNotification: 5000,
			notificationVisibility: true,
		}
	},
	props: {
		selecionouCidade: {
			type: Boolean,
		},
		cidadeSelecionada: {
			type: String,
		},
	},
	methods: {
		truncateString(input, length) {
			if (input.length > length) {
				return input.substring(0, length) + "..."
			}
			return input
		},
		triggerTooltip() {
			setTimeout(() => {
				this.notificationVisibility = false
			}, this.timeoutNotification)
		},
	},
	mounted() {
		this.triggerTooltip()
	},
}
</script>

<style scoped lang="scss">
.tooltip {
	position: relative;
	display: inline-block;
}

.tooltip .tooltiptext {
	visibility: hidden;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	opacity: 0;
	transition: opacity 0.3s;
	padding: 6px;
	color: #fff;
	letter-spacing: 0;
	font-size: 16px;
	line-height: 24px;
	font-weight: normal;
	border-radius: 4px;
	background-color: var(--tooltip-bg-color);
	transition: opacity 300ms ease 0s;
	animation-duration: 0.4s;
	visibility: visible;
	opacity: 1;
	font-size: 12px;
	line-height: 15px;
	width: 182px;
	left: 0;
	margin-left: 0;
	bottom: -72px;
	img {
		width: 6px;
	}
	@media (min-width: 768px) {
		width: 630px;
		right: -32px;
		left: inherit;
		top: 28px;
		font-size: 16px;
		line-height: 19px;
		padding: 8px 16px;
		height: 35px;
		img {
			width: 12px;
		}
	}
}

.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: -10px;
	left: 24px;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent var(--tooltip-bg-color) transparent;
	@media (min-width: 768px) {
		right: 11px;
		left: inherit;
		border-width: 0 13px 22px 13px;
	}
}

.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}
.cep-text-color {
	color: var(--cep-text-color);
}
.ktm {
	.local-text {
		display: none;
	}
	.cep-text {
		display: none;
		@media (min-width: 768px) {
			display: block;
		}
	}
	.tooltip .tooltiptext {
		margin-left: -14px;
	}
}
</style>
